import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import './scss/style.scss';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//IF WE USE HASHROUTER THEN WILL GET # IN THE URL EX.3000/#/DASHBORAD
import { useCookies } from 'react-cookie';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
//LAZY LOAD IMPROVES THE REACT BASED APPLICATION PERFORMANCE
//LAZY LOAD SPLITS THE BUNDLE.JS FILE BASED ON THE ROUTES HERE IT WILL CREATE A SEPARATE FILE FOR BOTH LOGIN AND REGISTER.
// Containers

// Pages 
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
//ETA pages
const AdminLogin = React.lazy(() => import('./views/AdminViews/Logins/Adminlogin'));

//Admin layout and views
const AppConstants = React.lazy(() => import('./views/AdminViews/AppConstants'));

const TheLayoutAdmin = React.lazy(() => import('./containerAdmin/TheLayoutAdmin'));


const AddNewUser = React.lazy(() => import('./views/AdminViews/User/UserAdd'));
const UserUpdate = React.lazy(() => import('./views/AdminViews/User/UserAdd/UserUpdate'));
const UserList = React.lazy(() => import('./views/AdminViews/User/UserLIst'));
const UserDetails = React.lazy(() => import('./views/AdminViews/User/UserDetails'));
const UserActions = React.lazy(() => import('./views/AdminViews/User/UserLIst/UserActionLIst'));
const RequestCredit = React.lazy(() => import('./views/AdminViews/User/EmployeeReimbursement/RequestCredit'));
const UploadExpenseBills = React.lazy(() => import('./views/AdminViews/User/EmployeeReimbursement/UploadExpenseBills'));
const ExpenseList = React.lazy(() => import('./views/AdminViews/User/EmployeeReimbursement/ExpenseList'));

const BOM = React.lazy(() => import('./views/AdminViews/BOM'));
const BomList = React.lazy(() => import('./views/AdminViews/BOM/BomList'));
const BomDetails = React.lazy(() => import('./views/AdminViews/BOM/BomDetails'));

const AddNewPartnumber = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/AddNewPartnumber'));
const PartnumberList = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartnumberList'));
const PartnumberImport = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartnumberImport'));
const PartNumberDetails = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartnumberDetails'));
// const UpdatePartNumber = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/UpdatePartNumber'));

// const AddNewCustomer = React.lazy(() => import('./views/AdminViews/Customer/CustomerAdd'));
// const CustomerList = React.lazy(() => import('./views/AdminViews/Customer/CustomerList'));
// const CustomerDetails = React.lazy(() => import('./views/AdminViews/Customer/CustomerDetails'));
const AddNewCustomer = React.lazy(() => import('./views/AdminViews/CustomerInformation/CustomerInformationAdd'));
const CustomerList = React.lazy(() => import('./views/AdminViews/CustomerInformation/CustomerInformationList'));
const CustomerDetails = React.lazy(() => import('./views/AdminViews/CustomerInformation/CustomerInformationDetails'));
const CustomerUpdate = React.lazy(() => import('./views/AdminViews/CustomerInformation/CustomerInfromationEdit'));
const CustomerImport = React.lazy(() => import('./views/AdminViews/Customer/CustomerImport'));

// const AddNewSupplier = React.lazy(() => import('./views/AdminViews/Suppliers/AddNewSupplier'));
// const SupplierList = React.lazy(() => import('./views/AdminViews/Suppliers/SupplierList'));
// const UpdateSupplier = React.lazy(() => import('./views/AdminViews/Suppliers/SupplierUpdate'));
const AddNewSupplier = React.lazy(() => import('./views/AdminViews/SupplierInformation/SupplierInformationAdd'));
const SupplierList = React.lazy(() => import('./views/AdminViews/SupplierInformation/SupplierInformationList'));
const UpdateSupplier = React.lazy(() => import('./views/AdminViews/SupplierInformation/SupplierInformationEdit'));
const SupplierDetails = React.lazy(() => import('./views/AdminViews/SupplierInformation/SupplierInformationDetails'));
const SupplierImport = React.lazy(() => import('./views/AdminViews/Suppliers/SupllierImport'));
// const SupplierDetails = React.lazy(() => import('./views/AdminViews/Suppliers/SuppliersDetails'));
// const SupplierActionList = React.lazy(() => import('./views/AdminViews/Suppliers/SupActionLIst'));

const BasiclSetting = React.lazy(() => import('./views/AdminViews/Setting/BasicSettings'));
const GeneralSetting = React.lazy(() => import('./views/AdminViews/Setting/GeneralSetting'));
const GeneralSettingUpdate = React.lazy(() => import('./views/AdminViews/Setting/GeneralSettingUpdate'));
const PrefixSetting = React.lazy(() => import('./views/AdminViews/Setting/PrefixSetting'));
const AddNewMailConfiguration = React.lazy(() => import('./views/AdminViews/Setting/MailConfiguration/AddNewMailConfiguration'));
const MailConfigurationList = React.lazy(() => import('./views/AdminViews/Setting/MailConfiguration/MailConfigurationList'));
const TermsConditionSetup = React.lazy(() => import('./views/AdminViews/Setting/TermsandConditions'));
const States = React.lazy(() => import('./views/AdminViews/Setting/States'));
const OtherTotalSetting = React.lazy(() => import('./views/AdminViews/Setting/OtherTotalSetting'));
const Countries = React.lazy(() => import('./views/AdminViews/Setting/Countries'));
const CompanyDetails = React.lazy(() => import('./views/AdminViews/Setting/CompanyDetails'));
const CompanyBankDetailsAdd = React.lazy(() => import('./views/AdminViews/Setting/BankDetails/BankDetailsAdd'));
const CompanyBankDetailsList = React.lazy(() => import('./views/AdminViews/Setting/BankDetails/BankDetailsList'));
const CompanyBankDetails = React.lazy(() => import('./views/AdminViews/Setting/BankDetails/BankDetails'));
const CurrencySetup = React.lazy(() => import('./views/AdminViews/Setting/CurrencySetup'));
const PaymantTypeSetup = React.lazy(() => import('./views/AdminViews/Setting/PaymantTypeSetup'));
const Department = React.lazy(() => import('./views/AdminViews/Setting/Department'));
const CurrencyConversion = React.lazy(() => import('./views/AdminViews/Setting/CurrencyConversion'));
const SettingPaymentTerms = React.lazy(() => import('./views/AdminViews/Setting/SettingPaymentTerms'));
const SettingDeliveryTerms = React.lazy(() => import('./views/AdminViews/Setting/SettingDeliveryTerms'));

const AddNewBranch = React.lazy(() => import('./views/AdminViews/Branch/AddNewBranch'));
const BranchList = React.lazy(() => import('./views/AdminViews/Branch/BranchList'));
const BranchDetails = React.lazy(() => import('./views/AdminViews/Branch/BranchDetails'));
const BranchUpdate = React.lazy(() => import('./views/AdminViews/Branch/BranchUpdate'));

const AddNewItem = React.lazy(() => import('./views/AdminViews/Items/CreateNewItem'));
const ItemList = React.lazy(() => import('./views/AdminViews/Items/ItemList'));
const Tax = React.lazy(() => import('./views/AdminViews/Items/TaxSetup'));
const TaxGroupSetup = React.lazy(() => import('./views/AdminViews/Items/TaxGroupSetup'));
const TaxGroupSetupDetails = React.lazy(() => import('./views/AdminViews/Items/TaxGroupSetupDetails'));
const Categories = React.lazy(() => import('./views/AdminViews/Items/Categories'));
const UnitsOfMeasurement = React.lazy(() => import('./views/AdminViews/Items/UnitOfMeasureMent'));

const FileTypeSetup = React.lazy(() => import('./views/AdminViews/Setting/FileTypeSetup/FileTypeSetup'));

const PartNumberCategory = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/PartNumCategory/PartNumCategory'));

const PartNumberNoteSetup = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/NoteSetup'));

const RawMaterialSetup = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/RawMaterialSetup'));

const TechnologySetup = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/TechnologySetup/TechnologySetup'));

const MaterialSetup = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/MaterialTypeSetup/MaterialTypeSetup'));

const ApprovalAuthorizationUserAdd = React.lazy(() => import('./views/AdminViews/ApprovalAuthorizationSettings/ApprovalAuthorizationUserAdd'));
const ApprovalAuthorization = React.lazy(() => import('./views/AdminViews/ApprovalAuthorizationSettings/ApprovalAuthorization'));

const ProcessFlowAdd = React.lazy(() => import('./views/AdminViews/ProcessFlow/ProcessFlowAdd'));
const ProcessFlowList = React.lazy(() => import('./views/AdminViews/ProcessFlow/ProcessFlowList'));
const ProcessFlowUpdate = React.lazy(() => import('./views/AdminViews/ProcessFlow/ProcessFlowUpdate'));
const ProcessFlowDetails = React.lazy(() => import('./views/AdminViews/ProcessFlow/ProcessFlowDetails'));

const UserRoleNameAdd = React.lazy(() => import('./views/AdminViews/User/UserRoles/RoleFeatureName/RoleFeatureNameAdd'));
const UserRoleAdd = React.lazy(() => import('./views/AdminViews/User/UserRoles/Role/RoleAdd'));
const UserRoleList = React.lazy(() => import('./views/AdminViews/User/UserRoles/Role/RoleList'));
const UserRoleDetails = React.lazy(() => import('./views/AdminViews/User/UserRoles/Role/RoleDetails'));

// --------------------------ETA-----------------------------------------
const CustEnqAdd = React.lazy(() => import('./views/AdminViews/RFQ/CreateNewRFQ'));
const CustEnqList = React.lazy(() => import('./views/AdminViews/RFQ/RFQList'));
const CustEnqDetails = React.lazy(() => import('./views/AdminViews/RFQ/RFQDetails'));
const RfqContentSetupAdd = React.lazy(() => import('./views/AdminViews/RfqContentSetup/RfqContentSetupAdd'));

const SupRFQDetails = React.lazy(() => import('./views/AdminViews/RFQ/SupplierRfqDetails'));
const SupRFQUpdate = React.lazy(() => import('./views/AdminViews/RFQ/SupplierRfqUpdate'));
const SupRFQList = React.lazy(() => import('./views/AdminViews/SupplierRFQList'));

const CostingList = React.lazy(() => import('./views/AdminViews/Costing/CostingList'));
const CostingDetails = React.lazy(() => import('./views/AdminViews/Costing/CostingDetails'));

const Routing = React.lazy(() => import('./views/AdminViews//RoutingPending/Routing'));
const RoutingPendingList = React.lazy(() => import('./views/AdminViews//RoutingPending'));

const CustomerPurchaseOrderAdd = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/AddCustomerPurchaseOrder'));
const CustomerPurchaseOrderList = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/CustomerPurchaseOrderList'));
const CustomerPurchaseOrderDetails = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/CustomerPurchaseOrderDetails'));
//const CustomerPurchaseOrderUpdate = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/UpdateCustomerPurchaseOrder'));
const CustomerPurchaseOrderUpdate = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/CustPurchaseOrderUpdate'));

const WorkOrderList = React.lazy(() => import('./views/AdminViews/WorkOrder'));
const WorkOrderPendingList = React.lazy(() => import('./views/AdminViews/WorkOrder/WorkOrderPendingList'));
const WorkOrderDetails = React.lazy(() => import('./views/AdminViews/WorkOrder/WorkOrderDetails'));

const QuestionaireList = React.lazy(() => import('./views/AdminViews/Questionaire'));
const QuestionaireDetails = React.lazy(() => import('./views/AdminViews/Questionaire/QuestionaireDetails'));
const AddOAQuestionaire = React.lazy(() => import('./views/AdminViews/Questionaire/OAQuestionaire'));
const ProjInputSummary = React.lazy(() => import('./views/AdminViews/Questionaire/ProjectInputSummary'));


const QualityPlanPendingList = React.lazy(() => import('./views/AdminViews/QualityPlanSetup'));
const QualityPlanSetupAdd = React.lazy(() => import('./views/AdminViews/QualityPlanSetup'));
const QualityPlanSetupList = React.lazy(() => import('./views/AdminViews/QualityPlanSetup/QualityPlanSetupList'));
const QualityPlanSetupDetails = React.lazy(() => import('./views/AdminViews/QualityPlanSetup/QualityPlanSetupDetails'));

const OAList = React.lazy(() => import('./views/AdminViews/OA'));
const OADetails = React.lazy(() => import('./views/AdminViews/OA/OADetails'));

const StrategicPlanningList = React.lazy(() => import('./views/AdminViews/Strategic Planning/GenerateStrategicPlanning'));
const GenerateStrategicPlanning = React.lazy(() => import('./views/AdminViews/Strategic Planning/GenerateStrategicPlanning'));

const StrategicPlanningListPPC = React.lazy(() => import('./views/AdminViews/Strategic Planning PPC'));
const StrategicPlanningDetailsPPC = React.lazy(() => import('./views/AdminViews/Strategic Planning PPC/StrategucPlanningDetailsPPC'));

const RMBuyerDecisionPending = React.lazy(() => import('./views/AdminViews/RMBuyerDecisionPending/RMBuyerDecisionPending'));

const MaterialAllotmentandMRPPending = React.lazy(() => import('./views/AdminViews/MaterialAllotmentandMRPPending/MaterialAllotmentandMRPPending'));

const SupplierPurchaseOrder = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrder/AddSupplierPurchaseOrder'));
const SupplierPurchaseOrderList = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrder/SupplierPurchaseOrderList'));
const SupplierPurchaseOrderDetails = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrder/SupplierPurchaseOrderDetails'));

const ProductionPlanningList = React.lazy(() => import('./views/AdminViews/ProductionPlanning/ProductionPlanningList'));

const MachinePlanningCalender = React.lazy(() => import('./views/AdminViews/MachinePlanning/MachinePlanningCalender'));
const MachinePlanningList = React.lazy(() => import('./views/AdminViews/MachinePlanning/MachinePlanningList'));

const MaterialAllottedList = React.lazy(() => import('./views/AdminViews/MaterialAllottedList/MaterialAllottedList'));

const NewRCNCPRG = React.lazy(() => import('./views/AdminViews/NewRC/NewRCList'));

const NewRCRMReqList = React.lazy(() => import('./views/AdminViews/NewRC/RMRequestList'));

const RCDetails = React.lazy(() => import('./views/AdminViews/NewRC/RouteCardDetails'));
const OptrRCList = React.lazy(() => import('./views/AdminViews/NewRC/OperatorRouteCardList'));
const OptrRCDetails = React.lazy(() => import('./views/AdminViews/NewRC/OptrRouteCardDetails'));

// const AddGRN = React.lazy(() => import('./views/AdminViews/GRN/AddGrn'));
// const ListGRN = React.lazy(() => import('./views/AdminViews/GRN/GRNList'));
// const GRNInwInpList = React.lazy(() => import('./views/AdminViews/GRN/GRNList/GRNInwardInspectionList'))
// const GRNListByStatus = React.lazy(() => import('./views/AdminViews/GRN/GRNListByStatus'));
// const GRNDetails = React.lazy(() => import('./views/AdminViews/GRN/GRNList/GRNDetails'));
// const GRNEdit = React.lazy(() => import('./views/AdminViews/GRN/GRNList/GRNUpdate'));
// const GRNInsDetails = React.lazy(() => import('./views/AdminViews/GRN/GRNList/GRNInsDetails'));


const WIPInwardRequestList = React.lazy(() => import('./views/AdminViews/WIPInwardRequestList/WIPInwardRequestList'));
const WIPOutwardRequestList = React.lazy(() => import('./views/AdminViews/WIPInwardRequestList/WIPOutwardRequestList'));
const EnquiryFeasibilityAdd = React.lazy(() => import('./views/AdminViews/EnquiryFeasibility/EnquiryFeasibilityAdd'));
const InProgressRCList = React.lazy(() => import('./views/AdminViews/OptrDashboard/InProgressRCList'));

const WipInwardAdd = React.lazy(() => import('./views/AdminViews/WIPInwardRequestList/WIPInwardAdd'));
const WipOutwardAdd = React.lazy(() => import('./views/AdminViews/WIPInwardRequestList/WIPOutwardAdd'));

const MachinePerformance = React.lazy(() => import('./views/AdminViews/MachinePerformance'));


const RouteCardList = React.lazy(() => import('./views/AdminViews/NewRouteCardList'));
const RouteCardDetails = React.lazy(() => import('./views/AdminViews/NewRouteCardList/RouteCardDetails'));

const QrcodeList = React.lazy(() => import('./views/AdminViews/NewRC/QrCodeList'));


const BatchTestingRequestList = React.lazy(() => import('./views/AdminViews/NewRouteCardList/BatchTestingRequestList'));
const ProdRouteCardList = React.lazy(() => import('./views/AdminViews/NewRouteCardList/NewProdRouteCardList'));
const ProdRouteCardDetails = React.lazy(() => import('./views/AdminViews/NewRouteCardList/ProdRouteCardDetails'));
const QRCode = React.lazy(() => import('./views/AdminViews//NewRouteCardList/QRCode'));

const InprocessInspection = React.lazy(() => import('./views/AdminViews/NewRouteCardList/Inspection/InprocessInspection'));
const FinalInspection = React.lazy(() => import('./views/AdminViews/NewRouteCardList/Inspection/FinalInspection'));
const InwardInspection = React.lazy(() => import('./views/AdminViews/NewRouteCardList/Inspection/InwardInspection'));
const SettingApprovalInspection = React.lazy(() => import('./views/AdminViews/NewRouteCardList/Inspection/SettingApprovalInspection'));

const CreateNewQuotation = React.lazy(() => import('./views/AdminViews/CustomerQuotation/AddCustomerQuotation'));
const QuotationList = React.lazy(() => import('./views/AdminViews/CustomerQuotation/CustomerQuotationList'));
const QuotationDetails = React.lazy(() => import('./views/AdminViews/CustomerQuotation/CustomerQuotationDetails'));

const ToolsAndFixturesList = React.lazy(() => import('./views/AdminViews/ToolsAndFixtures'));
const InstrumentRequiredList = React.lazy(() => import('./views/AdminViews/InstrumentsRequired'));

const OptrRouteCardList = React.lazy(() => import('./views/AdminViews/NewRouteCardList/OperatorRouteCardList'));
const OptrRouteCardDetails = React.lazy(() => import('./views/AdminViews/NewRouteCardList/OptrRouteCardDetails'));


// --------------------------ETA Dashbord-----------------------------------------
const AdminDashboard = React.lazy(() => import('./views/AdminViews/AdminDashboard'));
const Businessashboard = React.lazy(() => import('./views/AdminViews/BusinessDevDashboard'));
const NPDDashboard = React.lazy(() => import('./views/AdminViews/NDPDashbord'));
const PPCDashboard = React.lazy(() => import('./views/AdminViews/PPCDasgboard'));
const PurcDashboard = React.lazy(() => import('./views/AdminViews/PurchaseDashboard'));
const StoreDashboard = React.lazy(() => import('./views/AdminViews/StoreDashboard'));
const QualityDashboard = React.lazy(() => import('./views/AdminViews/QualityDashboard'));
//const OptrDashboard = React.lazy(() => import('./views/AdminViews/OptrDashboard'));
const OptrDashboard = React.lazy(() => import('./views/AdminViews/OptrDashboard/index'));
const EnggDashboard = React.lazy(() => import('./views/AdminViews/EnggDashboard'));
const MethodsDashboard = React.lazy(() => import('./views/AdminViews/MethodsDashboard'));
const ProjDashboard = React.lazy(() => import('./views/AdminViews/ProjectDashboard'));
const ToolCribDashboard = React.lazy(() => import('./views/AdminViews/ToolCribDashboard'));
const AccountsDashboard = React.lazy(() => import('./views/AdminViews/AccountsDashboard'));
const MRDashboard = React.lazy(() => import('./views/AdminViews/MRDashboard'));
const HandworkDashboard = React.lazy(() => import('./views/AdminViews/HandworkDashboard'));

const SwitchDashboards = React.lazy(() => import('./views/AdminViews/SwitchDash/SwitchDashboard'));

//****************************************New Componets************************************************************** */

const AddNewCustPartNumber = React.lazy(() => import('./views/AdminViews/CustPartNumber/PartNumAdd'));
const CustPartNumberDetails = React.lazy(() => import('./views/AdminViews/CustPartNumber/PartnumberDetails'));
const CustPartNumberDetail = React.lazy(() => import('./views/AdminViews/CustPartNumber/PartnumberDetailsForOthers'));
const CustPartNumberList = React.lazy(() => import('./views/AdminViews/CustPartNumber/PartnumberList'));
const CustPartNumberUpdate = React.lazy(() => import('./views/AdminViews/CustPartNumber/PartNumUpdate'));
const RMPartNumberAdd = React.lazy(() => import('./views/AdminViews/CustPartNumber/RawMaterialPartNumAdd'));
const RMPartNumberUpdate = React.lazy(() => import('./views/AdminViews/CustPartNumber/RawMaterialPartNumUpdate'));
const RMPartNumberDetails = React.lazy(() => import('./views/AdminViews/CustPartNumber/RMPartNumberDetails'));


const RMSizePendingList = React.lazy(() => import('./views/AdminViews/Reports/RMSizePendingList'));
const RMSizeList = React.lazy(() => import('./views/AdminViews/Reports/RMSizeList'));
const FinishedPartPendingList = React.lazy(() => import('./views/AdminViews/Reports/FinishedPartPendingList'));
const FinishedPartList = React.lazy(() => import('./views/AdminViews/Reports/FinishedPartList'));
const QuestionnairePendingList = React.lazy(() => import('./views/AdminViews/Reports/QuestionnairePendingList'));
const RouthingPendingList = React.lazy(() => import('./views/AdminViews/Reports/RoutingPendingList'));
const NcPrgPendingList = React.lazy(() => import('./views/AdminViews/Reports/NCPrgPendingList'));

// Questionnaire
const QuestionnaireQuestionsSetup = React.lazy(() => import('./views/AdminViews/QaQuestionnaire/QuestionsSetup/QuestionsSetup'));
const QAQuestionnaireAdd = React.lazy(() => import('./views/AdminViews/QaQuestionnaire/QaQuestionnaire/QaQuestionnaireAdd'));
const QAQuestionnaireList = React.lazy(() => import('./views/AdminViews/QaQuestionnaire/QaQuestionnaire/QaQuestionnaireList'));
const QaQuestionnaireUpdate = React.lazy(() => import('./views/AdminViews/QaQuestionnaire/QaQuestionnaire/QaQuestionnaireUpdate'));
const QaQuestionnaireDetails = React.lazy(() => import('./views/AdminViews/QaQuestionnaire/QaQuestionnaire/QaQuestionnaireDetails'));

const AddDocumentCategories = React.lazy(() => import('./views/AdminViews/InternalDocument/DocumentCategories'));
const DocumentCategoriesDetails = React.lazy(() => import('./views/AdminViews/InternalDocument/DocumentCategories/DocumentCategoriesDetails'));

const DocumentSubCategory = React.lazy(() => import('./views/AdminViews/InternalDocument/DocumentSubcategory'));
const InteranlDocumentNames = React.lazy(() => import('./views/AdminViews/InternalDocument/DocumentNames/InternalDocumentNames'));
const InternalDocumentList = React.lazy(() => import('./views/AdminViews/InternalDocument/DocumentNames/InternalDocumentList'));
const InternalDocumentDetails = React.lazy(() => import('./views/AdminViews/InternalDocument/DocumentNames/InternalDocumentList/InternalDocumentDetails'));


const AddExternalDocumentCategories = React.lazy(() => import('./views/AdminViews/ExternalDocuments/ExternalDocumentCategory'));
const ExternalDocumentCategoriesDetails = React.lazy(() => import('./views/AdminViews/ExternalDocuments/ExternalDocumentCategory/ExternalDocumentCategoriesDetails'));
const ExternalDocumentSubCategory = React.lazy(() => import('./views/AdminViews/ExternalDocuments/ExternalDocumentSubCategory'));
const ExternalDocumentNames = React.lazy(() => import('./views/AdminViews/ExternalDocuments/ExternalDocumentNames'));
const ExternalDocumentList = React.lazy(() => import('./views/AdminViews/ExternalDocuments/ExternalDocumentList'));
const ExternalDocumentDetails = React.lazy(() => import('./views/AdminViews/ExternalDocuments/ExternalDocumentList/ExternalDocumentDetails'));

const AddCharacteristics = React.lazy(() => import('./views/AdminViews/Characteristics'));
const CharacteristicsDetails = React.lazy(() => import('./views/AdminViews/Characteristics/CharacteristicsDetails'));

const BatchCreationPendingList = React.lazy(() => import('./views/AdminViews/BatchCreation/BatchCreationPendingList'));
const BatchList = React.lazy(() => import('./views/AdminViews/BatchCreation/BatchList'));
const BatchDetails = React.lazy(() => import('./views/AdminViews/BatchCreation/BatchDetails'));

const StagingDwgQualityPlanPending = React.lazy(() => import('./views/AdminViews/StagingDwg/StagingDwgQualityPlanPending'));
const StagingUpdateQualityPlan = React.lazy(() => import('./views/AdminViews/StagingDwg/StagingUpdateQualityPlan'));
//const StageDrawingQualityPlanDetails=React.lazy(()=>import('./views/AdminViews/StagingDwg/StagedrawingQualityPlanDetails'));

const StageDrawingQualityPlanList = React.lazy(() => import('./views/AdminViews/StagingDwg/StageDrawingQualityPlanList'));
const StageDrawingQualityPlanDetails = React.lazy(() => import('./views/AdminViews/StagingDwg/StagedrawingQualityPlanDetails'));
const StageDrawingQualityPlanDetail = React.lazy(() => import('./views/AdminViews/StagingDwg1/StagedrawingQualityPlanDetails'));

const MachineAdd = React.lazy(() => import('./views/AdminViews/Machine/MachineSAdd'));
const MachineList = React.lazy(() => import('./views/AdminViews/Machine/MachineList'));
const MachineDetails = React.lazy(() => import('./views/AdminViews/Machine/MachineDetails'));

const FixtureAdd = React.lazy(() => import('./views/AdminViews/Fixtures/FixtureAdd'));
const FixtureList = React.lazy(() => import('./views/AdminViews/Fixtures/FixtureList'));
const FixtureDetails = React.lazy(() => import('./views/AdminViews/Machine/MachineDetails'));

// const InstrumetnsAdd = React.lazy(() => import('./views/AdminViews/Instruments/InstrumentsAdd'));
// const InstrumetnsList = React.lazy(() => import('./views/AdminViews/Instruments/InstrumentsList'));
// const InstrumetnsDetails = React.lazy(() => import('./views/AdminViews/Instruments/InstrumentsDetails'));

const InstrumetnsAdd = React.lazy(() => import('./views/AdminViews/InstrumentInformation/InstrumentInformationAdd'));
const InstrumetnsList = React.lazy(() => import('./views/AdminViews/InstrumentInformation/InstrumentInformationList'));
const InstrumetnsDetails = React.lazy(() => import('./views/AdminViews/InstrumentInformation/InstrumentInformationDetails'));
const InstrumetnsUpdate = React.lazy(() => import('./views/AdminViews/InstrumentInformation/InstrumentInformationUpdate'));

const ToolAdd = React.lazy(() => import('./views/AdminViews/Tools/ToolAdd'));
const ToolList = React.lazy(() => import('./views/AdminViews/Tools/ToolList'));
const ToolDetails = React.lazy(() => import('./views/AdminViews/Tools/ToolsDetails'));

const RoutingList = React.lazy(() => import('./views/AdminViews/Reports/RouthingList'));
const NCPrgList = React.lazy(() => import('./views/AdminViews/Reports/NCProgramList'));


{/* RM Allotment and MRP  */ }
const RMAllotmentAndMRPPendingList = React.lazy(() => import('./views/AdminViews/RMAllotmentAndMRPPendingList/RMAllotmentAndMRPPendingList'));
const CustomerRMAAllotmentList = React.lazy(() => import('./views/AdminViews/CustomerRMAAllotment/CustomerRMAAllotmentList'));
const CustomerRMAAllotmentDetails = React.lazy(() => import('./views/AdminViews/CustomerRMAAllotment/CustomerRMAAllotmentDetails'));

const OperationSetup = React.lazy(() => import('./views/AdminViews/OperationsSetup/AddOperationsSetup'));

const RawMaterialReportSetup = React.lazy(() => import('./views/AdminViews/RawMaterialReportDocumentSetup/AddRawMaterialReportDocumnetSetup'));

const AddSupplierPO = React.lazy(() => import('./views/AdminViews/SupplierPO/SupplierPOAdd'));
const SupplierRMPOList = React.lazy(() => import('./views/AdminViews/SupplierPO/SupplierPOList'));
const SupplierRMPOUpdate = React.lazy(() => import('./views/AdminViews/SupplierPO/SupplierPOUpdate'));
const SupplierRMPODetails = React.lazy(() => import('./views/AdminViews/SupplierPO/SupplierPODetails'));

const PartNumberFeasibilityReviewRecordDetails = React.lazy(() => import('./views/AdminViews/PartNumberFeasibilityReviewRecord/PartNumberFeasibilityReviewRecordDetails'));

const FeasibilityReviewRecordSetupAdd = React.lazy(() => import('./views/AdminViews/FeasibilityReviewRecoredSetup/FeasibilityReviewRecordSetupAdd'));
const PartNumberFeasibilityReviewRecordAdd = React.lazy(() => import('./views/AdminViews/PartNumberFeasibilityReviewRecord/PartNumberFeasibilityReviewRecordAdd'));
const PartNumberFeasibilityReviewRecordList = React.lazy(() => import('./views/AdminViews/PartNumberFeasibilityReviewRecord/PartNumberFeasibilityReviewRecordList'));

const ContractReviewRecordList = React.lazy(() => import('./views/AdminViews/ContractReviewRecord/ContractReviewRecordList'));

const CreateTestingAdd = React.lazy(() => import('./views/AdminViews/CreateTesting/CreateTestingAdd'));
const CreateTestingList = React.lazy(() => import('./views/AdminViews/CreateTesting/CreateTestingList'));
const InspectionSetupDetails = React.lazy(() => import('./views/AdminViews/CreateTesting/TestingDetails'));

const StrategicPlanningPendingList = React.lazy(() => import('./views/AdminViews/Strategic Planning/StrategicPlanningPendingList'));
const StrategicPlannigList = React.lazy(() => import('./views/AdminViews/Strategic Planning/StrategicplanningList'));
const StrategicPlannigDetails = React.lazy(() => import('./views/AdminViews/Strategic Planning/StrategicPlanDetails'));

const CostProcessSetupAdd = React.lazy(() => import('./views/AdminViews/CostProcessSetup/CostProcessSetUpAdd'))

// grn
const AddGRN = React.lazy(() => import('./views/AdminViews/GRN/AddGrn'));
const ListGRN = React.lazy(() => import('./views/AdminViews/GRN/GRNList'));
const GRNListByStatus = React.lazy(() => import('./views/AdminViews/GRN/GRNListByStatus'));
const GRNIDDetails = React.lazy(() => import('./views/AdminViews/GRN/GRNList/GRNDetails'));
const GRNEdit = React.lazy(() => import('./views/AdminViews/GRN/GRNList/GRNUpdate'));
const GrnPendingList = React.lazy(() => import('./views/AdminViews/GRN/GrnPendingList'));
const GrnInspectionPendingList = React.lazy(() => import('./views/AdminViews/GRNInspectionPending/SupplierGoodsReceivedList'));
const GrnInspectionUpdate = React.lazy(() => import('./views/AdminViews/GRNInspectionPending/GRNInspectionUpdate'));




//ProductionPlanList
const ProductionRCPendingList = React.lazy(() => import('./views/AdminViews/ProductionPlanning/ProductionRCPendingList'));
const ProductionRCList = React.lazy(() => import('./views/AdminViews/ProductionPlanning/ProductionRCList'));
const ProductionPlanPendingList = React.lazy(() => import('./views/AdminViews/ProductionPlanning/ProductionPlanPending'));
const ProductionPlanList = React.lazy(() => import('./views/AdminViews/ProductionPlanning/ProductionPlanningList'));

{/* Job Work */ }
const SupPOJWPendingList = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderJW/SupPOJWPendingList'));
const SupPOJWAdd = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderJW/SupPOJWAdd'));
const SupPOJWList = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderJW/SupPOJWList'));
const SupPOJWDetails = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderJW/SupPOJWListDetails'));
const SupPOJWAddUpdate = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderJW/SupPOJWAddUpdate'));

// added on 2782024
const RfqFeasibilityReviewRecordPendingList = React.lazy(() => import('./views/AdminViews/RfqFeasibilityReviewRecord/RfqFeasibilityReviewRecordPendingList'));

const RfqFeasibilityReviewRecordAdd = React.lazy(() => import('./views/AdminViews/RfqFeasibilityReviewRecord/RfqFeasibilityReviewRecordAdd'));
const RfqFeasibilityReviewRecordList = React.lazy(() => import('./views/AdminViews/RfqFeasibilityReviewRecord/RfqFeasibilityReviewRecordList'));
const RfqFeasibilityReviewRecordDetails = React.lazy(() => import('./views/AdminViews/RfqFeasibilityReviewRecord/RfqFeasibilityReviewRecordDetails'));

{/* Non Stock*/ }
const SupPONSAdd = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderNS/SupPONSAdd'));
const SupPONSDetails = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderNS/SupPONSDetails'));
const SupPONSList = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderNS/SupPONSList'));
const SupPONSUpdate = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrderNS/SupPONSUpdate'));

{/* Setup*/ }
const AddCitySetup = React.lazy(() => import('./views/AdminViews/CitySetup/AddCitySetup'))
const AddCustomerCategory = React.lazy(() => import('./views/AdminViews/CustomerCategorySetup/AddCustomerCategory'))
const AddSupplierCategorySetup = React.lazy(() => import('./views/AdminViews/SupplierCategorySetup/AddSupplierCategorySetup'))
const MacActCategorySetupAdd = React.lazy(() => import('./views/AdminViews/MachineActivityCategorySetup/MacActCategorySetupAdd'));
const AddFrequencyOfInspection = React.lazy(() => import('./views/AdminViews/FrequencyOfInspectionSetup/AddFrequencyOfInspection'))
const MachineCategoryAdd = React.lazy(() => import('./views/AdminViews/MachineCategory/MachineCategoryAdd'))
const BoxTypesSetupAdd = React.lazy(() => import('./views/AdminViews/BoxTypesSetup/BoxTypesSetupAdd'))
const MachineActivitiesAdd = React.lazy(() => import('./views/AdminViews/MachineActivitiesSetup/MachineActivitiesAdd'));
const BusinessVerticleSetupAdd = React.lazy(() => import('./views/AdminViews/BusinessVerticleSetup/BusinessVerticleSetupAdd'))
const AddShiftSetups = React.lazy(() => import('./views/AdminViews/ShiftSetups/AddShiftSetups'));
const OtherFinishingProcessSetupAdd = React.lazy(() => import('./views/AdminViews/OtherFinishingProcessSetup/OtherFinishingProcessSetupAdd'));
const AddCellSetup = React.lazy(() => import('./views/AdminViews/CellSetup/AddCellSetup'));

{/* Purchase Indent*/ }
const PurchaseIndentAdd = React.lazy(() => import('./views/AdminViews/PurchaseIndent/PurchaseIndentAdd'));
const PurchaseIndentDetails = React.lazy(() => import('./views/AdminViews/PurchaseIndent/PurchaseIndentDetails'))
const PurchaseIndentUpdate = React.lazy(() => import('./views/AdminViews/PurchaseIndent/PurchaseIndentUpdate'))
const PurchaseIndentList = React.lazy(() => import('./views/AdminViews/PurchaseIndent/PurchaseIndentList'))


{/* Items(Part Number Consumbles and Assets) */ }
const ItemInformationAdd = React.lazy(() => import('./views/AdminViews/Item/ItemInformationAdd'))
const ItemInformationDetails = React.lazy(() => import('./views/AdminViews/Item/ItemInformationDetails'))
const ItemInformationList = React.lazy(() => import('./views/AdminViews/Item/ItemInformationList'))
const ItemInformationEdit = React.lazy(() => import('./views/AdminViews/Item/ItemInformationEdit'))

{/* JW DC*/ }
const JwDCPendingList = React.lazy(() => import('./views/AdminViews/DeliveryChallanJW/DCJwPendingList'));
const JwDCAdd = React.lazy(() => import('./views/AdminViews/DeliveryChallanJW/JWDCAdd'));
const JwDCList = React.lazy(() => import('./views/AdminViews/DeliveryChallanJW/JWDCList'));
const JwDCDetails = React.lazy(() => import('./views/AdminViews/DeliveryChallanJW/JWDCDetails'));
const JwDCUpdate = React.lazy(() => import('./views/AdminViews/DeliveryChallanJW/JWDCUpdate'));

{/* General DC*/ }
const GeneralDCAdd = React.lazy(() => import('./views/AdminViews/DeliveryChallanGeneral/DeliveryChallanGeneralAdd'));
const GeneralDCList = React.lazy(() => import('./views/AdminViews/DeliveryChallanGeneral/DeliveryChallanGeneralList'));
const GeneralDCDetails = React.lazy(() => import('./views/AdminViews/DeliveryChallanGeneral/DeliveryChallanGeneralDetails'));
const GeneralDCUpdate = React.lazy(() => import('./views/AdminViews/DeliveryChallanGeneral/DeliveryChallanGeneralUpdate'));

{/* Dispatch*/ }
const DispatchCPendingList = React.lazy(() => import('./views/AdminViews/Dispatch/DispatchPendingList'));
const DispatchAdd = React.lazy(() => import('./views/AdminViews/Dispatch/DispatchAdd'));
const DispatchList = React.lazy(() => import('./views/AdminViews/Dispatch/DispatchList'));
const DispatchDetails = React.lazy(() => import('./views/AdminViews/Dispatch/DispatchDetails'));
const DispatchUpdate = React.lazy(() => import('./views/AdminViews/Dispatch/DispatchUpdate'));

{/* Dispatch*/ }
const PackingPendingList = React.lazy(() => import('./views/AdminViews/Packing/PackingPendingList'));
const PackingAdd = React.lazy(() => import('./views/AdminViews/Packing/PackingAdd'));
const PackingList = React.lazy(() => import('./views/AdminViews/Packing/PackingList'));
const PackingDetails = React.lazy(() => import('./views/AdminViews/Packing/PackingDetails'));
const PackingUpdate = React.lazy(() => import('./views/AdminViews/Packing/PackingUpdate'));


{/* Cust Invoice*/ }
const CustInvoicePendingList = React.lazy(() => import('./views/AdminViews/CustomerInvoice/DispatchListForInvoicePending'));
const CustInvoiceAdd = React.lazy(() => import('./views/AdminViews/CustomerInvoice/CustomerInvoiceAdd'));
const CustInvoiceList = React.lazy(() => import('./views/AdminViews/CustomerInvoice/CustomerInvoiceList'));
const CustInvoiceDetails = React.lazy(() => import('./views/AdminViews/CustomerInvoice/CustomerInvoiceDetails'));
const CustInvoiceUpdate = React.lazy(() => import('./views/AdminViews/CustomerInvoice/CustomerInvoiceUpdate'));

{/* Inspections*/ }
const InspectionPendingList = React.lazy(() => import('./views/AdminViews/QualityInspection/InspectionPendingList'));
const InspectionRequestList = React.lazy(() => import('./views/AdminViews/QualityInspection/InspectionRequestList'));
const InspectionList = React.lazy(() => import('./views/AdminViews/StagingDwg/InspectionList'));
const UpdateInspection = React.lazy(() => import('./views/AdminViews/StagingDwg/InspectionUpdate'));
const InspectionDetials = React.lazy(() => import('./views/AdminViews/StagingDwg/InspectionDetails'));
const FinalInspectionPending = React.lazy(() => import('./views/AdminViews/QualityDashboard/FinalInspectionPending'));

{/* SupplierPriceSetup */ }
const SupplierPriceSetupAdd = React.lazy(() => import('./views/AdminViews/SupplierPriceSetup/SupplierPriceSetupAdd'));
const SupplierPriceSetupList = React.lazy(() => import('./views/AdminViews/SupplierPriceSetup/SupplierPriceSetupList'));
const SupplierPriceSetupDetails = React.lazy(() => import('./views/AdminViews/SupplierPriceSetup/SupplierPriceSetupDetails'));
const SupplierPriceSetupUpdate = React.lazy(() => import('./views/AdminViews/SupplierPriceSetup/SupplierPriceSetupUpdate'));

{/* MFRIP */ }
const MFRIPInspectionPendingList = React.lazy(() => import('./views/AdminViews/MFRIPInspectionList/MFRIPInspectionPendingList'));

{/* DocApprovalPendingList */ }
const DocApprovalPendingList = React.lazy(() => import('./views/AdminViews/Reports/DocumentApprovalPendingList'));

{/* Tasks */ }
const TaskPendingListByStatusText = React.lazy(() => import('./views/AdminViews/Task/TaskPendingListByStatusText'));
const TaskAdd = React.lazy(() => import('./views/AdminViews/Task/AddNewTask'));
const TaskList = React.lazy(() => import('./views/AdminViews/Task/TaskList'));
const TaskDetails = React.lazy(() => import('./views/AdminViews/Task/TaskDetails'));
const TaskUpdate = React.lazy(() => import('./views/AdminViews/Task/TaskUpdate'));

{/*Hadiya Stocks*/ }
const FeasibilityRFQList = React.lazy(() => import('./views/AdminViews/RfqFeasibilityReviewRecord/PNFeasibilityRFQ'));

const StocksList = React.lazy(() => import('./views/AdminViews/Stocks/FinishedGoods'));
const StocksListRm = React.lazy(() => import('./views/AdminViews/Stocks/StocksListsForRM'));
const StocksListWIPAcc = React.lazy(() => import('./views/AdminViews/Stocks/StocksListsForWIPAcc'));
const StocksListWIPRew = React.lazy(() => import('./views/AdminViews/Stocks/StocksListsForWIPRew'));

const RCGanttChart = React.lazy(() => import('./views/AdminViews/GanntChart'));

{/* Tasks */ }
const OAOpenOrderList = React.lazy(() => import('./views/AdminViews/Reports/OAOpenOrderList'));
const OARegisterList = React.lazy(() => import('./views/AdminViews/Reports/OARegisterList'));

const ToolReceivePendingList = React.lazy(() => import('./views/AdminViews/Tools/ToolReceivePendingList'));
const FixturesReceivePendingList = React.lazy(() => import('./views/AdminViews/Fixtures/FixturesReceivePendingList'));
const RMIssueNoteListAll = React.lazy(() => import('./views/AdminViews/NewRC/RMIssueNoteListAll'));


const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <Layout><Component {...props}></Component></Layout>
    )}>
    </Route>
  );
}

const App = () => {
  const [cookies, setCookies, removeCookie] = useCookies(['admin']);


  return (
    // <BrowserRouter>
    <HashRouter>
      <React.Suspense fallback={loading}>
        {/* {!cookies.user_type ? (<AdminLogin />) : ( */}
        <Switch>
          {/*Admin*/}

          <AppRoute exact path='/appconstants' name='Admin Dashboard' layout={TheLayoutAdmin} component={AppConstants} />


          <AppRoute exact path='/adduser' name='Add New User' layout={TheLayoutAdmin} component={AddNewUser} />
          <AppRoute exact path='/userupdate' name='User Update' layout={TheLayoutAdmin} component={UserUpdate} />
          <AppRoute exact path='/userlist' name='User List' layout={TheLayoutAdmin} component={UserList} />
          <AppRoute exact path='/userdetails' name='User Details' layout={TheLayoutAdmin} component={UserDetails} />
          <AppRoute exact path='/user-actions' name='User Actions' layout={TheLayoutAdmin} component={UserActions} />
          <AppRoute exact path='/request-credit' name='RequestCredit' layout={TheLayoutAdmin} component={RequestCredit} />
          <AppRoute exact path='/upload-expense-bills' name='UploadExpenseBills' layout={TheLayoutAdmin} component={UploadExpenseBills} />
          <AppRoute exact path='/expense-reimbursement-list' name='ExpenseList' layout={TheLayoutAdmin} component={ExpenseList} />

          <AppRoute exact path='/bom' name='BOM' layout={TheLayoutAdmin} component={BOM} />
          <AppRoute exact path='/bom-list' name='Bom List' layout={TheLayoutAdmin} component={BomList} />
          <AppRoute exact path='/bom-details' name='Bom Details' layout={TheLayoutAdmin} component={BomDetails} />

          <AppRoute exact path='/createpartnumber' name='Add New Partnumber' layout={TheLayoutAdmin} component={AddNewPartnumber} />
          <AppRoute exact path='/partnumberlist' name='Partnumber List' layout={TheLayoutAdmin} component={PartnumberList} />
          <AppRoute exact path='/importpartnumbers' name='Partnumber Import' layout={TheLayoutAdmin} component={PartnumberImport} />
          <AppRoute exact path='/partnumberdetails' name='Partnumber Details' layout={TheLayoutAdmin} component={PartNumberDetails} />
          {/* <AppRoute exact path='/updatepartnumber' name='Partnumber Update' layout={TheLayoutAdmin} component={UpdatePartNumber} /> */}

          <AppRoute exact path='/addnewcustomer' name='Add New Customer' layout={TheLayoutAdmin} component={AddNewCustomer} />
          <AppRoute exact path='/customerlist' name='Customer List' layout={TheLayoutAdmin} component={CustomerList} />
          <AppRoute exact path='/customerdetails' name='Customer Details' layout={TheLayoutAdmin} component={CustomerDetails} />
          <AppRoute exact path='/customerupdate' name='Customer Details' layout={TheLayoutAdmin} component={CustomerUpdate} />
          <AppRoute exact path='/importcustomers' name='Customer Import' layout={TheLayoutAdmin} component={CustomerImport} />

          <AppRoute exact path='/addnewsupplier' name='Add New Supplier' layout={TheLayoutAdmin} component={AddNewSupplier} />
          <AppRoute exact path='/supplierlist' name='Supplier List' layout={TheLayoutAdmin} component={SupplierList} />
          <AppRoute exact path='/supplieredit' name='Supplier Update' layout={TheLayoutAdmin} component={UpdateSupplier} />
          <AppRoute exact path='/importsupplier' name='Supplier Import' layout={TheLayoutAdmin} component={SupplierImport} />
          <AppRoute exact path='/supplierdetails' name='Supplier Details' layout={TheLayoutAdmin} component={SupplierDetails} />
          {/* <AppRoute exact path='/sup-action-list' name='Supplier Action List' layout={TheLayoutAdmin} component={SupplierActionList} /> */}

          <AppRoute exact path='/basicsettings' name='General Setting' layout={TheLayoutAdmin} component={BasiclSetting} />
          <AppRoute exact path='/generalsettings' name='General Setting' layout={TheLayoutAdmin} component={GeneralSetting} />
          <AppRoute exact path='/companydetailsupdate' name='General Setting' layout={TheLayoutAdmin} component={GeneralSettingUpdate} />
          <AppRoute exact path='/prefixsettings' name='Prefix Setting' layout={TheLayoutAdmin} component={PrefixSetting} />
          <AppRoute exact path='/addnewmailconfiguration' name='Add New Mail Configuration' layout={TheLayoutAdmin} component={AddNewMailConfiguration} />
          <AppRoute exact path='/mailconfiguration' name='Mail Configuration' layout={TheLayoutAdmin} component={MailConfigurationList} />
          <AppRoute exact path='/termsconditionsetup' name='Terms Condition Setup' layout={TheLayoutAdmin} component={TermsConditionSetup} />
          <AppRoute exact path='/states' name='States' layout={TheLayoutAdmin} component={States} />
          <AppRoute exact path='/othertotalsetting' name='OtherTotalSetting' layout={TheLayoutAdmin} component={OtherTotalSetting} />
          <AppRoute exact path='/currency-setup' name='CurrencySetup' layout={TheLayoutAdmin} component={CurrencySetup} />
          <AppRoute exact path='/payment-type-setup' name='PaymantTypeSetup' layout={TheLayoutAdmin} component={PaymantTypeSetup} />
          <AppRoute exact path='/department' name='Department' layout={TheLayoutAdmin} component={Department} />
          <AppRoute exact path='/currency-conversion' name='CurrencyConversion' layout={TheLayoutAdmin} component={CurrencyConversion} />
          <AppRoute exact path='/settingpaymentterms' name='SettingPaymentTerms' layout={TheLayoutAdmin} component={SettingPaymentTerms} />
          <AppRoute exact path='/settingdeliveryterms' name='SettingDeliveryTerms' layout={TheLayoutAdmin} component={SettingDeliveryTerms} />

          <AppRoute exact path='/countries' name='Countries' layout={TheLayoutAdmin} component={Countries} />
          <AppRoute exact path='/companydetails' name='Company Details' layout={TheLayoutAdmin} component={CompanyDetails} />
          <AppRoute exact path='/companybankdetailsadd' name='Company Bank Details' layout={TheLayoutAdmin} component={CompanyBankDetailsAdd} />
          <AppRoute exact path='/banklist' name='Company Bank List' layout={TheLayoutAdmin} component={CompanyBankDetailsList} />
          <AppRoute exact path='/bankdetails' name='Company Bank Details' layout={TheLayoutAdmin} component={CompanyBankDetails} />

          <AppRoute exact path='/addnewbranch' name='Add New Branch' layout={TheLayoutAdmin} component={AddNewBranch} />
          <AppRoute exact path='/branchlist' name='Branch List' layout={TheLayoutAdmin} component={BranchList} />
          <AppRoute exact path='/branchdetails' name='Branch Details' layout={TheLayoutAdmin} component={BranchDetails} />
          <AppRoute exact path='/branchupdate' name='Branch Update' layout={TheLayoutAdmin} component={BranchUpdate} />

          <AppRoute exact path='/createnewitem' name='Add New Item' layout={TheLayoutAdmin} component={AddNewItem} />
          <AppRoute exact path='/itemlist' name='Item List' layout={TheLayoutAdmin} component={ItemList} />
          <AppRoute exact path='/taxes' name='Tax Setup' layout={TheLayoutAdmin} component={Tax} />
          <AppRoute exact path='/taxgroup' name='Tax Group Setup' layout={TheLayoutAdmin} component={TaxGroupSetup} />
          <AppRoute exact path='/tax-group-details' name='Tax Group Setup Details' layout={TheLayoutAdmin} component={TaxGroupSetupDetails} />
          <AppRoute exact path='/categories' name='Categories' layout={TheLayoutAdmin} component={Categories} />
          <AppRoute exact path='/technologysetup' name='TechnologySetup' layout={TheLayoutAdmin} component={TechnologySetup} />
          <AppRoute exact path='/materilasetup' name='Material Setup' layout={TheLayoutAdmin} component={MaterialSetup} />
          <AppRoute exact path='/unitsofmeasurement' name='UnitsOfMeasurement' layout={TheLayoutAdmin} component={UnitsOfMeasurement} />

          <AppRoute exact path='/filetypesetup' name='File Type Setup' layout={TheLayoutAdmin} component={FileTypeSetup} />

          <AppRoute exact path='/partnumcategory' name='Part Num Category' layout={TheLayoutAdmin} component={PartNumberCategory} />
          <AppRoute exact path='/partnumnotesetup' name='Part Num Note Setup' layout={TheLayoutAdmin} component={PartNumberNoteSetup} />
          <AppRoute exact path='/rawmaterialsetup' name='Raw Material Setup' layout={TheLayoutAdmin} component={RawMaterialSetup} />

          <AppRoute exact path='/add-app-auth-user' name='ApprovalAuthorizationUserAdd' layout={TheLayoutAdmin} component={ApprovalAuthorizationUserAdd} />
          <AppRoute exact path='/add-app-auth' name='ApprovalAuthorization' layout={TheLayoutAdmin} component={ApprovalAuthorization} />

          <AppRoute exact path='/process-flow' name='ProcessFlowAdd' layout={TheLayoutAdmin} component={ProcessFlowAdd} />
          <AppRoute exact path='/process-flow-list' name='ProcessFlowList' layout={TheLayoutAdmin} component={ProcessFlowList} />
          <AppRoute exact path='/process-flow-update' name='ProcessFlowUpdate' layout={TheLayoutAdmin} component={ProcessFlowUpdate} />
          <AppRoute exact path='/process-flow-details' name='ProcessFlowDetails' layout={TheLayoutAdmin} component={ProcessFlowDetails} />
          <AppRoute exact path='/user-role-name-add' name='UserRoleNameAdd' layout={TheLayoutAdmin} component={UserRoleNameAdd} />

          <AppRoute exact path='/user-role-add' name='UserRoleAdd' layout={TheLayoutAdmin} component={UserRoleAdd} />
          <AppRoute exact path='/user-role-list' name='UserRoleAdd' layout={TheLayoutAdmin} component={UserRoleList} />
          <AppRoute exact path='/user-role-details' name='UserRoleDetails' layout={TheLayoutAdmin} component={UserRoleDetails} />

          {/* **************************** * ETA********************************************** */}
          <AppRoute exact path='/cust-enq-add' name='CustEnqAdd' layout={TheLayoutAdmin} component={CustEnqAdd} />
          <AppRoute exact path='/cust-enq-list' name='CustEnqList' layout={TheLayoutAdmin} component={CustEnqList} />
          <AppRoute exact path='/rfqdetails' name='CustEnqDetails' layout={TheLayoutAdmin} component={CustEnqDetails} />
          <AppRoute exact path='/add-rfq-content-setup' name='RfqContentSetupAdd' layout={TheLayoutAdmin} component={RfqContentSetupAdd} />

          <AppRoute exact path='/suprfqdetails' name='Supplier RFQ Details' layout={TheLayoutAdmin} component={SupRFQDetails} />
          <AppRoute exact path='/suprfqupdate' name='Supplier RFQ Update' layout={TheLayoutAdmin} component={SupRFQUpdate} />
          <AppRoute exact path='/Suprfqlist' name='Sup RFQ List' layout={TheLayoutAdmin} component={SupRFQList} />

          <AppRoute exact path='/costing-list' name='CostingList' layout={TheLayoutAdmin} component={CostingList} />
          <AppRoute exact path='/costing-details' name='CostingDetails' layout={TheLayoutAdmin} component={CostingDetails} />

          <AppRoute exact path='/routing' name='Routing' layout={TheLayoutAdmin} component={Routing} />
          <AppRoute exact path='/routing-pending-list' name='RoutingPendingList' layout={TheLayoutAdmin} component={RoutingPendingList} />

          <AppRoute exact path='/customer-purchase-order-add' name='Customer Purchase Order Add' layout={TheLayoutAdmin} component={CustomerPurchaseOrderAdd} />
          <AppRoute exact path='/customer-purchase-order-list' name='Customer Purchase Order List' layout={TheLayoutAdmin} component={CustomerPurchaseOrderList} />

          <AppRoute exact path='/customer-purchase-order-details' name='Customer Purchase Order Details' layout={TheLayoutAdmin} component={CustomerPurchaseOrderDetails} />
          <AppRoute exact path='/customer-purchase-order-update' name='Customer Purchase Order Update' layout={TheLayoutAdmin} component={CustomerPurchaseOrderUpdate} />


          <AppRoute exact path='/work-order-list' name='Work Order List' layout={TheLayoutAdmin} component={WorkOrderList} />
          <AppRoute exact path='/work-order-pen-list' name='Work Order Pending List' layout={TheLayoutAdmin} component={WorkOrderPendingList} />
          <AppRoute exact path='/work-order-details' name='Work Order Details' layout={TheLayoutAdmin} component={WorkOrderDetails} />

          <AppRoute exact path='/questionaire-list' name='Questionaire List' layout={TheLayoutAdmin} component={QuestionaireList} />
          <AppRoute exact path='/questionaire-details' name='Questionaire Details' layout={TheLayoutAdmin} component={QuestionaireDetails} />
          <AppRoute exact path='/qa-questionaire-add' name='Add OA Questionaire' layout={TheLayoutAdmin} component={AddOAQuestionaire} />
          <AppRoute exact path='/project-summary-add' name='Project Input Summary' layout={TheLayoutAdmin} component={ProjInputSummary} />

          <AppRoute exact path='/plan-pending-list' name='QualityPlanPendingList' layout={TheLayoutAdmin} component={QualityPlanPendingList} />
          <AppRoute exact path='/plan-setup' name='QualityPlanSetupAdd' layout={TheLayoutAdmin} component={QualityPlanSetupAdd} />
          <AppRoute exact path='/plan-setup-list' name='QualityPlanSetupList' layout={TheLayoutAdmin} component={QualityPlanSetupList} />
          <AppRoute exact path='/plan-setup-details' name='QualityPlanSetupDetails' layout={TheLayoutAdmin} component={QualityPlanSetupDetails} />

          <AppRoute exact path='/oa-list' name='OA List' layout={TheLayoutAdmin} component={OAList} />
          <AppRoute exact path='/oa-details' name='OA Details' layout={TheLayoutAdmin} component={OADetails} />

          {/* <AppRoute exact path='/strategic-planning-list' name='Strategic Planning List' layout={TheLayoutAdmin} component={StrategicPlanningList} />
          <AppRoute exact path='/strategic-planning' name='Strategic Planning' layout={TheLayoutAdmin} component={GenerateStrategicPlanning} />

          <AppRoute exact path='/strategic-plan-list' name='Strategic Planning List PPC' layout={TheLayoutAdmin} component={StrategicPlanningListPPC} />
          <AppRoute exact path='/strategic-plan-details' name='Strategic Planning' layout={TheLayoutAdmin} component={StrategicPlanningDetailsPPC} /> */}

          <AppRoute exact path='/rm-buyer-decision-pending' name='RMBuyerDecisionPending' layout={TheLayoutAdmin} component={RMBuyerDecisionPending} />
          <AppRoute exact path='/material-allotment-and-mrp-pending' name='MaterialAllotmentandMRPPending' layout={TheLayoutAdmin} component={MaterialAllotmentandMRPPending} />

          <AppRoute exact path='/supplier-purchase-order-add' name='SupplierPurchaseOrderAdd' layout={TheLayoutAdmin} component={SupplierPurchaseOrder} />
          <AppRoute exact path='/supplier-purchase-order-list' name='SupplierPurchaseOrderList' layout={TheLayoutAdmin} component={SupplierPurchaseOrderList} />
          <AppRoute exact path='/supplier-purchase-order-details' name='SupplierPurchaseOrderDetails' layout={TheLayoutAdmin} component={SupplierPurchaseOrderDetails} />

          <AppRoute exact path='/production-planning-list' name='ProductionPlanningList' layout={TheLayoutAdmin} component={ProductionPlanningList} />

          <AppRoute exact path='/machine-planning-calender' name='MachinePlanningCalender' layout={TheLayoutAdmin} component={MachinePlanningCalender} />
          <AppRoute exact path='/machine-planning-list' name='MachinePlanningList' layout={TheLayoutAdmin} component={MachinePlanningList} />

          <AppRoute exact path='/material-allotted-list' name='Material Allotted List' layout={TheLayoutAdmin} component={MaterialAllottedList} />

          <AppRoute exact path='/new-rc-nc-prg' name='New RC(NC PRG Pending)' layout={TheLayoutAdmin} component={NewRCNCPRG} />
          <AppRoute exact path='/new-rc-rm-req' name='New RC(Rm Req List)' layout={TheLayoutAdmin} component={NewRCRMReqList} />
          <AppRoute exact path='/rc-details' name='RC Details' layout={TheLayoutAdmin} component={RCDetails} />
          <AppRoute exact path='/optr-rc-list' name='Optr RC List' layout={TheLayoutAdmin} component={OptrRCList} />
          <AppRoute exact path='/optr-route-card-details' name='Optr RC Details' layout={TheLayoutAdmin} component={OptrRCDetails} />

          {/* <AppRoute exact path='/add-GRN' name='Add GRN' layout={TheLayoutAdmin} component={AddGRN} />
          <AppRoute exact path='/GRN-List' name='List GRN' layout={TheLayoutAdmin} component={ListGRN} />
          <AppRoute exact path='/GRN-Inw-Ins-List' name='GRNInwInpList' layout={TheLayoutAdmin} component={GRNInwInpList} />
          <AppRoute exact path='/GRN-List-Status' name='GRNListByStatus' layout={TheLayoutAdmin} component={GRNListByStatus} />
          <AppRoute exact path='/GRN-Details' name='GRN ID Details' layout={TheLayoutAdmin} component={GRNDetails} />
          <AppRoute exact path='/GRN-Edit' name='GRN Edit' layout={TheLayoutAdmin} component={GRNEdit} />
          <AppRoute exact path='/GRN-Ins-Details' name='GRN ID Details' layout={TheLayoutAdmin} component={GRNInsDetails} /> */}

          <AppRoute exact path='/Inward-inspection' name='InwardInspection' layout={TheLayoutAdmin} component={InwardInspection} />

          <AppRoute exact path='/wip-inward-req' name='WIPInwardRequestList' layout={TheLayoutAdmin} component={WIPInwardRequestList} />
          <AppRoute exact path='/wip-outward-req' name='WIPOutwardRequestList' layout={TheLayoutAdmin} component={WIPOutwardRequestList} />
          <AppRoute exact path='/inprogress-rc' name='InProgressRCList' layout={TheLayoutAdmin} component={InProgressRCList} />
          <AppRoute exact path='/inward-add' name='WipInwardAdd' layout={TheLayoutAdmin} component={WipInwardAdd} />
          <AppRoute exact path='/outward-add' name='WipInwardAdd' layout={WipOutwardAdd} component={WipOutwardAdd} />


          <AppRoute exact path='/enq-feasibility' name='EnquiryFeasibilityAdd' layout={TheLayoutAdmin} component={EnquiryFeasibilityAdd} />
          <AppRoute exact path='/machine-performance' name='Machine Performance' layout={TheLayoutAdmin} component={MachinePerformance} />

          <AppRoute exact path='/route-card-list' name='RouteCard List' layout={TheLayoutAdmin} component={RouteCardList} />
          <AppRoute exact path='/route-card-details' name='RouteCardDetails' layout={TheLayoutAdmin} component={RouteCardDetails} />

          {/* <AppRoute exact path='/qr-code-list' name='QrcodeList' layout={TheLayoutAdmin} component={QrcodeList} /> */}
          <Route exact path="/qr-code-list" name="QrcodeList" render={props => <QrcodeList {...props} />} />


          <AppRoute exact path='/batch-testing-request-list' name='BatchTestingRequestList' layout={TheLayoutAdmin} component={BatchTestingRequestList} />
          <AppRoute exact path='/prod-route-card-list' name='RouteCard List' layout={TheLayoutAdmin} component={ProdRouteCardList} />
          <AppRoute exact path='/prod-route-card-details' name='RouteCardDetails' layout={TheLayoutAdmin} component={ProdRouteCardDetails} />
          <AppRoute exact path='/batch-testing-processfi' name='FinalInspection' layout={TheLayoutAdmin} component={FinalInspection} />
          <AppRoute exact path='/batch-testing-processii' name='InprocessInspection' layout={TheLayoutAdmin} component={InprocessInspection} />
          <AppRoute exact path='/batch-testing-processinw' name='InwardInspection' layout={TheLayoutAdmin} component={InwardInspection} />
          <AppRoute exact path='/batch-testing-processsai' name='SettingApprovalInspection' layout={TheLayoutAdmin} component={SettingApprovalInspection} />

          <AppRoute exact path='/create-new-quotation' name='Create New Quotation' layout={TheLayoutAdmin} component={CreateNewQuotation} />
          <AppRoute exact path='/quotation-list' name='Quotation List' layout={TheLayoutAdmin} component={QuotationList} />
          <AppRoute exact path='/customer-quotation-details' name='Quotation Details' layout={TheLayoutAdmin} component={QuotationDetails} />

          <AppRoute exact path='/Tools-Fixtures-List' name='Tools And Fixtures List' layout={TheLayoutAdmin} component={ToolsAndFixturesList} />
          <AppRoute exact path='/ins-req-List' name='Instrument Required List' layout={TheLayoutAdmin} component={InstrumentRequiredList} />


          <AppRoute exact path='/optr-route-card-list' name='RouteCard List' layout={TheLayoutAdmin} component={OptrRouteCardList} />
          <AppRoute exact path='/optr-route-card-details' name='RouteCardDetails' layout={TheLayoutAdmin} component={OptrRouteCardDetails} />

          {/* ETA  Dashboard*/}
          <AppRoute exact path='/admin-dashboard' name='Admin Dashboard' layout={TheLayoutAdmin} component={AdminDashboard} />
          <AppRoute exact path='/business-dashboard' name='Business Dashboard' layout={TheLayoutAdmin} component={Businessashboard} />
          <AppRoute exact path='/npd-dashboard' name='NPD Dashboard' layout={TheLayoutAdmin} component={NPDDashboard} />
          <AppRoute exact path='/ppc-dashboard' name='PPC Dashboard' layout={TheLayoutAdmin} component={PPCDashboard} />
          <AppRoute exact path='/purchase-dashboard' name='Purc Dashboard' layout={TheLayoutAdmin} component={PurcDashboard} />
          <AppRoute exact path='/store-dashboard' name='Store Dashboard' layout={TheLayoutAdmin} component={StoreDashboard} />
          <AppRoute exact path='/quality-dashboard' name='Quality Dashboard' layout={TheLayoutAdmin} component={QualityDashboard} />
          <AppRoute exact path='/optr-dashboard' name='Optr Dashboard' layout={TheLayoutAdmin} component={OptrDashboard} />
          <AppRoute exact path='/engg-dashboard' name='Engg Dashboard' layout={TheLayoutAdmin} component={EnggDashboard} />
          <AppRoute exact path='/methods-dashboard' name='Methods Dashboard' layout={TheLayoutAdmin} component={MethodsDashboard} />
          <AppRoute exact path='/proj-dashboard' name='Proj Dashboard' layout={TheLayoutAdmin} component={ProjDashboard} />
          <AppRoute exact path='/tool-crib-dashboard' name='Tool Crib Dashboard' layout={TheLayoutAdmin} component={ToolCribDashboard} />
          <AppRoute exact path='/accounts-dashboard' name='Accounts Dashboard' layout={TheLayoutAdmin} component={AccountsDashboard} />
          <AppRoute exact path='/mr-dashboard' name='MR Dashboard' layout={TheLayoutAdmin} component={MRDashboard} />
          <AppRoute exact path='/handwork-dashboard' name='Handwork Dashboard' layout={TheLayoutAdmin} component={HandworkDashboard} />

          <AppRoute exact path='/switchdashboard' name='IssueInstrumentsAdd' layout={TheLayoutAdmin} component={SwitchDashboards} />
          {/* -------------------------------- New Routes ETA -------------------------------------------------*/}
          {/*Cust Part Num*/}
          <AppRoute path='/cust-part-num-new' name='AddNewCustPartNumber' layout={TheLayoutAdmin} component={AddNewCustPartNumber} />
          <AppRoute path='/cust-part-num-details' name='CustPartNumberDetails' layout={TheLayoutAdmin} component={CustPartNumberDetails} />
          <AppRoute path='/cust-part-num-detail' name='CustPartNumberDetail' layout={TheLayoutAdmin} component={CustPartNumberDetail} />
          <AppRoute path='/cust-part-num-list' name='CustPartNumberList' layout={TheLayoutAdmin} component={CustPartNumberList} />
          <AppRoute exact path='/updatepartnumber' name='Partnumber Update' layout={TheLayoutAdmin} component={CustPartNumberUpdate} />
          <AppRoute path='/rm-part-num' name='RMPartNumberAdd' layout={TheLayoutAdmin} component={RMPartNumberAdd} />
          <AppRoute path='/rm-part-num-update' name='RMPartNumberUpdate' layout={TheLayoutAdmin} component={RMPartNumberUpdate} />
          <AppRoute path='/rm-part-num-details' name='RMPartNumberDetails' layout={TheLayoutAdmin} component={RMPartNumberDetails} />

          <AppRoute path='/rm-size-pending-list' name='RMSizePendingList' layout={TheLayoutAdmin} component={RMSizePendingList} />
          <AppRoute path='/rm-size-list' name='RMSizeList' layout={TheLayoutAdmin} component={RMSizeList} />
          <AppRoute path='/finished-part-pending-list' name='FinishedPartPendingList' layout={TheLayoutAdmin} component={FinishedPartPendingList} />
          <AppRoute path='/finished-part-list' name='FinishedPartList' layout={TheLayoutAdmin} component={FinishedPartList} />
          <AppRoute path='/questionnaire-pending-list' name='QuestionnairePendingList' layout={TheLayoutAdmin} component={QuestionnairePendingList} />
          <AppRoute path='/routhing-pending-list' name='RouthingPendingList' layout={TheLayoutAdmin} component={RouthingPendingList} />
          <AppRoute path='/ncprg-pending-list' name='NcPrgPendingList' layout={TheLayoutAdmin} component={NcPrgPendingList} />

          {/* Questionnaire */}
          <AppRoute path='/questionnaire-question-setup' name='QuestionnaireQuestionsSetup' layout={TheLayoutAdmin} component={QuestionnaireQuestionsSetup} />
          <AppRoute path='/qa-questionnaire-new' name='QAQuestionnaireAdd' layout={TheLayoutAdmin} component={QAQuestionnaireAdd} />
          <AppRoute path='/qa-questionnaire-list' name='QAQuestionnaireList' layout={TheLayoutAdmin} component={QAQuestionnaireList} />
          <AppRoute path='/qa-questionnaire-update' name='QAQuestionnaireUpdate' layout={TheLayoutAdmin} component={QaQuestionnaireUpdate} />
          <AppRoute path='/qa-questionnaire-details' name='QaQuestionnaireDetails' layout={TheLayoutAdmin} component={QaQuestionnaireDetails} />

          {/* internal documents */}

          <AppRoute path='/add-new-document-category' name='Add Document Categories' layout={TheLayoutAdmin} component={AddDocumentCategories} />
          <AppRoute path='/document-category-details' name='DocumentCategoriesDetails' layout={TheLayoutAdmin} component={DocumentCategoriesDetails} />
          <AppRoute path='/documents/document-sub-category' name='DocumentSubCategory' layout={TheLayoutAdmin} component={DocumentSubCategory} />
          <AppRoute path='/documents/internal-document-names' name='InteranlDocumentNames' layout={TheLayoutAdmin} component={InteranlDocumentNames} />
          <AppRoute path='/documents/internal-document-list' name='InternalDocumentList' layout={TheLayoutAdmin} component={InternalDocumentList} />
          <AppRoute path='/documents/internal-document-details/:id' name='InternalDocumentDetails' layout={TheLayoutAdmin} component={InternalDocumentDetails} />

          {/* external documents */}
          <AppRoute path='/documents/external-document-category' name='AddExternalDocumentCategories' layout={TheLayoutAdmin} component={AddExternalDocumentCategories} />
          <AppRoute path='/documents/external-document-category-details/:id' name='ExternalDocumentCategoriesDetails' layout={TheLayoutAdmin} component={ExternalDocumentCategoriesDetails} />
          <AppRoute path='/documents/external-document-sub-category' name='ExternalDocumentSubCategory' layout={TheLayoutAdmin} component={ExternalDocumentSubCategory} />
          <AppRoute path='/documents/external-document-names' name='ExternalDocumentNames' layout={TheLayoutAdmin} component={ExternalDocumentNames} />
          <AppRoute path='/documents/external-document-list' name='ExternalDocumentList' layout={TheLayoutAdmin} component={ExternalDocumentList} />
          <AppRoute path='/documents/external-document-details/:id' name='ExternalDocumentDetails' layout={TheLayoutAdmin} component={ExternalDocumentDetails} />

          {/*charactersistic setup  */}
          <AppRoute path='/characteristic-add' name='AddCharacteristics' layout={TheLayoutAdmin} component={AddCharacteristics} />
          <AppRoute path='/characteristic-details/:id' name='CharacteristicsDetails' layout={TheLayoutAdmin} component={CharacteristicsDetails} />

          <AppRoute exact path='/batch-creation-pending-list' name='BatchCreationPendingList' layout={TheLayoutAdmin} component={BatchCreationPendingList} />
          <AppRoute exact path='/batch-list' name='BatchList' layout={TheLayoutAdmin} component={BatchList} />
          <AppRoute exact path='/batch-details' name='BatchDetails' layout={TheLayoutAdmin} component={BatchDetails} />

          <AppRoute exact path='/staging-dwg-quality-plan-pending-list' name='StagingDwgQualityPlanPending' layout={TheLayoutAdmin} component={StagingDwgQualityPlanPending} />
          <AppRoute exact path='/staging-update-quality-plan-setup' name='StagingUpdateQualityPlan' layout={TheLayoutAdmin} component={StagingUpdateQualityPlan} />

          <AppRoute exact path='/stage-drawing-quality-plan-list' name='StageDrawingQualityPlanList' layout={TheLayoutAdmin} component={StageDrawingQualityPlanList} />
          <AppRoute exact path='/stage-drawing-quality-plan-details' name='StageDrawingQualityPlanDetails' layout={TheLayoutAdmin} component={StageDrawingQualityPlanDetails} />
          <AppRoute exact path='/stage-drawing-quality-plan-detail' name='StageDrawingQualityPlanDetail' layout={TheLayoutAdmin} component={StageDrawingQualityPlanDetail} />

          {/* palnning */}
          <AppRoute exact path='/strategic-planning-pending-list' name='Strategic Planning Pending List' layout={TheLayoutAdmin} component={StrategicPlanningPendingList} />
          <AppRoute exact path='/strategic-planning-list' name='Strategic Planning List' layout={TheLayoutAdmin} component={StrategicPlannigList} />
          <AppRoute exact path='/strategic-planning-details' name='Strategic Planning Details' layout={TheLayoutAdmin} component={StrategicPlannigDetails} />

          <AppRoute exact path='/machine-add' name='Machine Add' layout={TheLayoutAdmin} component={MachineAdd} />
          <AppRoute exact path='/machine-list' name='MachineList' layout={TheLayoutAdmin} component={MachineList} />
          <AppRoute exact path='/machine-deatils' name='MachineDetails' layout={TheLayoutAdmin} component={MachineDetails} />

          <AppRoute exact path='/fixture-add' name='Fixture Add' layout={TheLayoutAdmin} component={FixtureAdd} />
          <AppRoute exact path='/fixture-list' name='Fixture List' layout={TheLayoutAdmin} component={FixtureList} />
          <AppRoute exact path='/fixture-deatils' name='Fixture Details' layout={TheLayoutAdmin} component={FixtureDetails} />

          <AppRoute exact path='/tool-add' name='Tool Add' layout={TheLayoutAdmin} component={ToolAdd} />
          <AppRoute exact path='/tool-list' name='Tool List' layout={TheLayoutAdmin} component={ToolList} />
          <AppRoute exact path='/tool-details' name='Tool Details' layout={TheLayoutAdmin} component={ToolDetails} />

          <AppRoute exact path='/instrumets-add' name='InstrumetnsAdd' layout={TheLayoutAdmin} component={InstrumetnsAdd} />
          <AppRoute exact path='/instrumets-list' name='InstrumetnsList' layout={TheLayoutAdmin} component={InstrumetnsList} />
          <AppRoute exact path='/instrumets-deatils' name='InstrumetnsDetails' layout={TheLayoutAdmin} component={InstrumetnsDetails} />
          <AppRoute exact path='/instrumets-update' name='InstrumetnsUpdate' layout={TheLayoutAdmin} component={InstrumetnsUpdate} />

          <AppRoute exact path='/routing-list' name='RoutingList' layout={TheLayoutAdmin} component={RoutingList} />
          <AppRoute exact path='/nc-program-list' name='NCPrgList' layout={TheLayoutAdmin} component={NCPrgList} />

          {/* RM Allotment and MRP  */}
          <AppRoute exact path='/rmallotment-mrppending' name='RMAllotmentAndMRPPendingList' layout={TheLayoutAdmin} component={RMAllotmentAndMRPPendingList} />
          <AppRoute exact path='/rm-report-document' name='RawMaterialReportSetup' layout={TheLayoutAdmin} component={RawMaterialReportSetup} />
          <AppRoute exact path='/customer-rma-allotment-list' name='CustomerRMAAllotmentList' layout={TheLayoutAdmin} component={CustomerRMAAllotmentList} />
          <AppRoute exact path='/customer-rma-allotment-details' name='CustomerRMAAllotmentDetails' layout={TheLayoutAdmin} component={CustomerRMAAllotmentDetails} />


          <AppRoute exact path='/operation-setup' name='OperationSetup' layout={TheLayoutAdmin} component={OperationSetup} />

          <AppRoute exact path='/add-rm-supplier-po' name='AddSupplierPO' layout={TheLayoutAdmin} component={AddSupplierPO} />
          <AppRoute exact path='/supplier-rm-po-list' name='SupplierRMPOList' layout={TheLayoutAdmin} component={SupplierRMPOList} />
          <AppRoute exact path='/supplier-rm-po-update' name='SupplierRMPOUpdate' layout={TheLayoutAdmin} component={SupplierRMPOUpdate} />
          <AppRoute exact path='/supplier-rm-po-details' name='SupplierRMPODetails' layout={TheLayoutAdmin} component={SupplierRMPODetails} />

          <AppRoute exact path='/part-number-feasibility-review-record-details' name='PartNumberFeasibilityReviewRecordDetails' layout={TheLayoutAdmin} component={PartNumberFeasibilityReviewRecordDetails} />

          <AppRoute exact path='/feasibility-review-record-setup' name='FeasibilityReviewRecordSetupAdd' layout={TheLayoutAdmin} component={FeasibilityReviewRecordSetupAdd} />
          <AppRoute exact path='/part-number-feasibility-review-record-add' name='PartNumberFeasibilityReviewRecordAdd' layout={TheLayoutAdmin} component={PartNumberFeasibilityReviewRecordAdd} />
          <AppRoute exact path='/part-number-feasibility-review-record-list' name='PartNumberFeasibilityReviewRecordList' layout={TheLayoutAdmin} component={PartNumberFeasibilityReviewRecordList} />

          <AppRoute exact path='/contract-review-record-list' name='ContractReviewRecordList' layout={TheLayoutAdmin} component={ContractReviewRecordList} />

          <AppRoute exact path='/create-testing' name='CreateTesting' layout={TheLayoutAdmin} component={CreateTestingAdd} />
          <AppRoute exact path='/create-testing-list' name='CreateTestingList' layout={TheLayoutAdmin} component={CreateTestingList} />
          <AppRoute exact path='/inspection-setup-details' name='InspectionSetupDetails' layout={TheLayoutAdmin} component={InspectionSetupDetails} />

          <AppRoute exact path='/add-cost-process-setup' name='CostProcessSetupAdd' layout={TheLayoutAdmin} component={CostProcessSetupAdd} />


          {/*production-plan-list*/}
          <AppRoute exact path='/production-rc-pending-list' name='ProductionRCPendingList' layout={TheLayoutAdmin} component={ProductionRCPendingList} />
          <AppRoute exact path='/production-plan-pending-list' name='ProductionPlanPendingList' layout={TheLayoutAdmin} component={ProductionPlanPendingList} />
          <AppRoute exact path='/production-rc-list' name='ProductionRCList' layout={TheLayoutAdmin} component={ProductionRCList} />
          <AppRoute exact path='/production-plan-list' name='ProductionPlanList' layout={TheLayoutAdmin} component={ProductionPlanList} />

          {/* Job Work */}
          <AppRoute exact path='/spo-job-work-pending-list' name='SupPOJWPendingList' layout={TheLayoutAdmin} component={SupPOJWPendingList} />
          <AppRoute exact path='/add-new-job-work' name='SupPOJWAdd' layout={TheLayoutAdmin} component={SupPOJWAdd} />
          <AppRoute exact path='/job-work-list' name='SupPOJWList' layout={TheLayoutAdmin} component={SupPOJWList} />
          <AppRoute exact path='/job-work-details' name='SupPOJWDetails' layout={TheLayoutAdmin} component={SupPOJWDetails} />
          <AppRoute exact path='/job-work-update' name='SupPOJWAddUpdate' layout={TheLayoutAdmin} component={SupPOJWAddUpdate} />


          {/* GRN */}
          <AppRoute exact path='/add-GRN' name='Add GRN' layout={TheLayoutAdmin} component={AddGRN} />
          <AppRoute exact path='/GRN-List' name='List GRN' layout={TheLayoutAdmin} component={ListGRN} />
          <AppRoute exact path='/GRN-List-Status' name='GRNListByStatus' layout={TheLayoutAdmin} component={GRNListByStatus} />
          <AppRoute exact path='/GRN-Details' name='GRN ID Details' layout={TheLayoutAdmin} component={GRNIDDetails} />
          <AppRoute exact path='/GRN-Edit' name='GRN Edit' layout={TheLayoutAdmin} component={GRNEdit} />
          <AppRoute exact path='/GRN-Pending-List' name='Grn Pending List' layout={TheLayoutAdmin} component={GrnPendingList} />
          <AppRoute exact path='/grn-inspection-pending-list' name='Grn Inspection Pending List' layout={TheLayoutAdmin} component={GrnInspectionPendingList} />
          <AppRoute exact path='/grn-inspection-update' name='Grn Inspection Update' layout={TheLayoutAdmin} component={GrnInspectionUpdate} />

          {/* rfq feasibility reveiew record */}
          <AppRoute exact path='/rfq-feasibility-review-record-pending-list' name='Rfq Feasibility Review Record Pending List' layout={TheLayoutAdmin} component={RfqFeasibilityReviewRecordPendingList} />

          <AppRoute exact path='/rfq-feasibility-review-record-add' name='RfqFeasibilityReviewRecordAdd' layout={TheLayoutAdmin} component={RfqFeasibilityReviewRecordAdd} />
          <AppRoute exact path='/rfq-feasibility-review-record-list' name='RfqFeasibilityReviewRecordList' layout={TheLayoutAdmin} component={RfqFeasibilityReviewRecordList} />
          <AppRoute exact path='/rfq-feasibility-review-record-details' name='RfqFeasibilityReviewRecordDetails' layout={TheLayoutAdmin} component={RfqFeasibilityReviewRecordDetails} />

          {/* Non Stock*/}
          <AppRoute exact path='/new-non-stock-po' name='SupPONSAdd' layout={TheLayoutAdmin} component={SupPONSAdd} />
          <AppRoute exact path='/non-stock-po-details' name='SupPONSDetails' layout={TheLayoutAdmin} component={SupPONSDetails} />
          <AppRoute exact path='/non-stock-po-list' name='SupPONSList' layout={TheLayoutAdmin} component={SupPONSList} />
          <AppRoute exact path='/non-stock-po-update' name='SupPONSUpdate' layout={TheLayoutAdmin} component={SupPONSUpdate} />

          {/*Setup*/}
          <AppRoute exact path='/add-city-setup' name='AddCitySetup' layout={TheLayoutAdmin} component={AddCitySetup} />
          <AppRoute exact path='/add-customer-category-setup' name='AddCustomerCategory' layout={TheLayoutAdmin} component={AddCustomerCategory} />
          <AppRoute exact path='/add-supplier-category-setup' name='AddSupplierCategorySetup' layout={TheLayoutAdmin} component={AddSupplierCategorySetup} />
          <AppRoute exact path='/add-macact-category-setup' name='MacActCategorySetupAdd' layout={TheLayoutAdmin} component={MacActCategorySetupAdd} />
          <AppRoute exact path='/add-frequency-of-inspection' name='AddFrequencyOfInspection' layout={TheLayoutAdmin} component={AddFrequencyOfInspection} />
          <AppRoute exact path='/machine-category-add' name='MachineCategoryAdd' layout={TheLayoutAdmin} component={MachineCategoryAdd} />
          <AppRoute exact path='/add-box-types-setup' name='BoxTypesSetupAdd' layout={TheLayoutAdmin} component={BoxTypesSetupAdd} />
          <AppRoute exact path='/add-machine-activities' name='MachineActivities' layout={TheLayoutAdmin} component={MachineActivitiesAdd} />
          <AppRoute exact path='/add-business-verticle-setup' name='AddBusinessVerticleSetup' layout={TheLayoutAdmin} component={BusinessVerticleSetupAdd} />
          <AppRoute exact path='/add-shift-setups' name='AddShiftSetups' layout={TheLayoutAdmin} component={AddShiftSetups} />
          <AppRoute exact path='/add-other-finishing-process-setup' name='OtherFinishingProcessSetupAdd' layout={TheLayoutAdmin} component={OtherFinishingProcessSetupAdd} />
          <AppRoute exact path='/add-cell-setup' name='AddCellSetup' layout={TheLayoutAdmin} component={AddCellSetup} />

          {/* PurchaseIndent */}
          <AppRoute exact path='/purchase-indent-add' name='PurchaseIndentAdd' layout={TheLayoutAdmin} component={PurchaseIndentAdd} />
          <AppRoute exact path='/purchase-indent-list' name='PurchaseIndentList' layout={TheLayoutAdmin} component={PurchaseIndentList} />
          <AppRoute exact path='/purchase-indent-Details' name='PurchaseIndentDetails' layout={TheLayoutAdmin} component={PurchaseIndentDetails} />
          <AppRoute exact path='/purchase-indent-update' name='PurchaseIndentEdit' layout={TheLayoutAdmin} component={PurchaseIndentUpdate} />

          {/* Items(Part Number Consumbles and Assets) */}
          <AppRoute exact path='/item-information-add' name='ItemInformationAdd' layout={TheLayoutAdmin} component={ItemInformationAdd} />
          <AppRoute exact path='/item-information-list' name='ItemInformationList' layout={TheLayoutAdmin} component={ItemInformationList} />
          <AppRoute exact path='/item-information-Details' name='ItemInformationDetails' layout={TheLayoutAdmin} component={ItemInformationDetails} />
          <AppRoute exact path='/item-information-edit' name='ItemInformationEdit' layout={TheLayoutAdmin} component={ItemInformationEdit} />

          {/* Jw DC */}
          <AppRoute exact path='/jw-dc-pending-list' name='JwDCPendingList' layout={TheLayoutAdmin} component={JwDCPendingList} />
          <AppRoute exact path='/jw-dc-create' name='JwDCAdd' layout={TheLayoutAdmin} component={JwDCAdd} />
          <AppRoute exact path='/jw-dc-list' name='JwDCList' layout={TheLayoutAdmin} component={JwDCList} />
          <AppRoute exact path='/jw-dc-details' name='JwDCDetails' layout={TheLayoutAdmin} component={JwDCDetails} />
          <AppRoute exact path='/jw-dc-update' name='JwDCUpdate' layout={TheLayoutAdmin} component={JwDCUpdate} />

          {/* General DC */}
          {/* <AppRoute exact path='/general-dc-pending-list' name='GeneralDCPendingList' layout={TheLayoutAdmin} component={GeneralDCPendingList} /> */}
          <AppRoute exact path='/general-dc-create' name='GeneralDCAdd' layout={TheLayoutAdmin} component={GeneralDCAdd} />
          <AppRoute exact path='/general-dc-list' name='GeneralDCList' layout={TheLayoutAdmin} component={GeneralDCList} />
          <AppRoute exact path='/general-dc-details' name='GeneralDCDetails' layout={TheLayoutAdmin} component={GeneralDCDetails} />
          <AppRoute exact path='/general-dc-update' name='GeneralDCUpdate' layout={TheLayoutAdmin} component={GeneralDCUpdate} />

          {/* Dispatch */}
          <AppRoute exact path='/dispatch-pending-list' name='DispatchCPendingList' layout={TheLayoutAdmin} component={DispatchCPendingList} />
          <AppRoute exact path='/create-new-dispatch' name='DispatchAdd' layout={TheLayoutAdmin} component={DispatchAdd} />
          <AppRoute exact path='/dispatch-list' name='DispatchList' layout={TheLayoutAdmin} component={DispatchList} />
          <AppRoute exact path='/dispatch-details' name='DispatchDetails' layout={TheLayoutAdmin} component={DispatchDetails} />
          <AppRoute exact path='/dispatch-update' name='DispatchUpdate' layout={TheLayoutAdmin} component={DispatchUpdate} />

          {/* Packing */}
          <AppRoute exact path='/packing-pending-list' name='PackingPendingList' layout={TheLayoutAdmin} component={PackingPendingList} />
          <AppRoute exact path='/create-new-packing' name='PackingAdd' layout={TheLayoutAdmin} component={PackingAdd} />
          <AppRoute exact path='/packing-list' name='PackingList' layout={TheLayoutAdmin} component={PackingList} />
          <AppRoute exact path='/packing-details' name='PackingDetails' layout={TheLayoutAdmin} component={PackingDetails} />
          <AppRoute exact path='/packing-update' name='PackingUpdate' layout={TheLayoutAdmin} component={PackingUpdate} />

          {/* Customer Invoices */}
          <AppRoute exact path='/cust-invoice-pending-list' name='CustInvoicePendingList' layout={TheLayoutAdmin} component={CustInvoicePendingList} />
          <AppRoute exact path='/create-new-cust-invoice' name='CustInvoiceAdd' layout={TheLayoutAdmin} component={CustInvoiceAdd} />
          <AppRoute exact path='/cust-invoice-list' name='CustInvoiceList' layout={TheLayoutAdmin} component={CustInvoiceList} />
          <AppRoute exact path='/cust-inv-details' name='CustInvoiceDetails' layout={TheLayoutAdmin} component={CustInvoiceDetails} />
          <AppRoute exact path='/cust-invoice-update' name='CustInvoiceUpdate' layout={TheLayoutAdmin} component={CustInvoiceUpdate} />

          {/* Inspections */}
          <AppRoute exact path='/inspection-pending-list' name='InspectionPendingList' layout={TheLayoutAdmin} component={InspectionPendingList} />
          <AppRoute exact path='/inspection-req-list' name='InspectionRequestList' layout={TheLayoutAdmin} component={InspectionRequestList} />
          <AppRoute exact path='/inspection-list' name='InspectionList' layout={TheLayoutAdmin} component={InspectionList} />
          <AppRoute exact path='/inspection-update' name='UpdateInspection' layout={TheLayoutAdmin} component={UpdateInspection} />
          <AppRoute exact path='/inspection-detail' name='InspectionDetials' layout={TheLayoutAdmin} component={InspectionDetials} />
          <AppRoute exact path='/final-inspection' name='FinalInspectionPending' layout={TheLayoutAdmin} component={FinalInspectionPending} />

          {/* SupplierPriceSetup */}
          <AppRoute exact path='/add-supplier-price' name='SupplierPriceSetupAdd' layout={TheLayoutAdmin} component={SupplierPriceSetupAdd} />
          <AppRoute exact path='/supplier-price-list' name='SupplierPriceSetupList' layout={TheLayoutAdmin} component={SupplierPriceSetupList} />
          <AppRoute exact path='/supplier-price-details' name='SupplierPriceSetupDetails' layout={TheLayoutAdmin} component={SupplierPriceSetupDetails} />
          <AppRoute exact path='/supplier-price-update' name='SupplierPriceSetupUpdate' layout={TheLayoutAdmin} component={SupplierPriceSetupUpdate} />
          {/* MFRIP */}
          <AppRoute exact path='/mfrip-inspection-pending-list' name='MFRIPInspectionPendingList' layout={TheLayoutAdmin} component={MFRIPInspectionPendingList} />

          {/* DocApprovalPendingList */}
          <AppRoute exact path='/approval-pending-list' name='DocApprovalPendingList' layout={TheLayoutAdmin} component={DocApprovalPendingList} />

          {/* Tasks */}
          <AppRoute exact path='/task-pending-list-by-status' name='TaskPendingListByStatusText' layout={TheLayoutAdmin} component={TaskPendingListByStatusText} />
          <AppRoute exact path='/add-new-task' name='TaskAdd' layout={TheLayoutAdmin} component={TaskAdd} />
          <AppRoute exact path='/task-list' name='TaskList' layout={TheLayoutAdmin} component={TaskList} />
          <AppRoute exact path='/task-details' name='TaskDetails' layout={TheLayoutAdmin} component={TaskDetails} />
          <AppRoute exact path='/task-update' name='TaskUpdate' layout={TheLayoutAdmin} component={TaskUpdate} />

          {/*Hadiya Stocks*/}
          <AppRoute exact path='/rfq-feasibility-list' name='FeasibilityRFQList' layout={TheLayoutAdmin} component={FeasibilityRFQList} />


          <AppRoute exact path='/stocks-list' name='StocksList' layout={TheLayoutAdmin} component={StocksList} />
          <AppRoute exact path='/stocks-list-rm' name='StocksListRm' layout={TheLayoutAdmin} component={StocksListRm} />
          <AppRoute exact path='/stocks-list-wip-acc' name='StocksListWIPAcc' layout={TheLayoutAdmin} component={StocksListWIPAcc} />
          <AppRoute exact path='/stocks-list-wip-rew' name='StocksListWIPRew' layout={TheLayoutAdmin} component={StocksListWIPRew} />

          <AppRoute exact path='/rc-gannt-chart' name='RCGanttChart' layout={TheLayoutAdmin} component={RCGanttChart} />

          {/* Repotrs */}
          <AppRoute exact path='/oa-open-order-list' name='OAOpenOrderList' layout={TheLayoutAdmin} component={OAOpenOrderList} />
          <AppRoute exact path='/oa-register-list' name='OARegisterList' layout={TheLayoutAdmin} component={OARegisterList} />

          <AppRoute exact path='/tool-receive-pending' name='ToolReceivePendingList' layout={TheLayoutAdmin} component={ToolReceivePendingList} />
          <AppRoute exact path='/fixtures-receive-pending' name='FixturesReceivePendingList' layout={TheLayoutAdmin} component={FixturesReceivePendingList} />
          <AppRoute exact path='/rm-issue' name='RMIssueNoteListAll' layout={TheLayoutAdmin} component={RMIssueNoteListAll} />

          {/* ETA */}
          <Route exact path="/adminlogin" name="Login Page" render={props => <AdminLogin {...props} />} />
          <Route exact path="/" name="Login Page" render={props => <AdminLogin {...props} />} />
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />

          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

        </Switch>
        {/* )} */}
      </React.Suspense>
      <ToastContainer
        draggable={false}
        transition={Zoom}//zoom out
        autoClose={2000}//closing after 8sec
        position="top-center"
      />
      {/* </BrowserRouter> */}
    </HashRouter>
  );
}


export default App;
